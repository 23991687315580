import { Box, Button, Divider, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import {
  DynamicDropDown,
  DynamicFile,
  DynamicMultiTextBox,
  DynamicNumber,
  DynamicTextBox,
  DynamicToggle
} from 'src/components/DynamicInputs';
import { ISetting } from '../types';
import arrayMutators from 'final-form-arrays';
import { useGetClientInfo, useGetPartnerConfig, useUpdateConfig } from 'src/api';
import { useEffect, useMemo, useState } from 'react';
import { transformOrganization } from 'src/helper';
import { IPartner } from 'src/types';
import { LoadingBackdrop } from 'src/components';
import { useSnackbarContext } from 'src/context/SnackBarContext';

const REPLACER = '@@';
const SEPARATOR = '.';

interface IDynamicPageProps {
  id: number;
  title: string;
}

interface IDynamicFormProps {
  settings: ISetting[] | undefined;
}

function renderControl(settings: ISetting) {
  settings.setting_key = settings.setting_key.replaceAll(SEPARATOR, REPLACER);
  switch (settings.setting_control_type) {
    case 'dropdown':
      return <DynamicDropDown settings={settings} />;
    case 'toggle':
      return <DynamicToggle settings={settings} />;
    case 'file':
      return <DynamicFile settings={settings} />;
    case 'textbox': {
      if (settings.setting_data_type === 'number') {
        return <DynamicNumber settings={settings} />;
      } else if (settings.multi_value) {
        return <DynamicMultiTextBox settings={settings} />;
      }
      return <DynamicTextBox settings={settings} />;
    }
    case 'url':
      return <DynamicTextBox settings={settings} />;
  }
}

const defaultEmptyList: IPartner[] = [];

export const DynamicPage = ({ id, title }: IDynamicPageProps) => {
  const { data: row } = useGetClientInfo(id);
  const data = useMemo(() => transformOrganization(row), [row]);
  const partners = data?.partners ?? defaultEmptyList;
  const [selected, setSelected] = useState(0);
  const { setState } = useSnackbarContext();
  const { mutateAsync } = useUpdateConfig({
    onSuccess: () => {
      setState({ type: 'success', text: 'Updated!', open: true });
    },
    onError: () => {
      setState({ type: 'error', text: "Error, couldn't update!", open: true });
    }
  });

  useEffect(() => {
    if (!selected && partners.length === 1) {
      setSelected(partners[0].partner_id);
    }
  }, [partners, selected]);
  const { data: config = [], isLoading } = useGetPartnerConfig(id, selected);

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  const section = Array.isArray(config) ? config.find(x => x.category_name === title) : null;
  const initValues = {} as any;
  const configObject = Array.isArray(config)
    ? config
        .flatMap(x => x.settings)
        .map(x => ({ [x.setting_key]: x.value }))
        .reduce((result, x) => ({ ...result, ...x }), {} as any)
    : {};
  for (const key in configObject) {
    initValues[key.replaceAll(SEPARATOR, REPLACER)] = configObject[key];
  }
  return (
    <Form
      onSubmit={async data => {
        const config = {} as any;
        for (const key in data) {
          config[key.replaceAll(REPLACER, SEPARATOR)] = data[key];
        }

        await mutateAsync({
          clientId: id,
          partnerId: selected as number,
          config
        });
      }}
      initialValues={initValues}
      mutators={{ ...arrayMutators }}
    >
      {({ handleSubmit, submitting }) => (
        <>
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                flexDirection: 'column',
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  height: 70,
                  display: 'flex',
                  flexDirection: 'row',
                  px: 4,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="title" sx={{ textTransform: 'capitalize' }}>
                  {title}
                </Typography>
                {partners.length > 1 && (
                  <Select
                    size="small"
                    sx={{ width: 200 }}
                    renderValue={selected => {
                      if (!selected) {
                        return 'Instance';
                      }

                      return partners.find(x => x.partner_id === selected)?.partner_name;
                    }}
                    value={selected}
                    onChange={e => setSelected(+e.target.value)}
                  >
                    {partners.map(({ partner_name, partner_id }) => (
                      <MenuItem key={partner_id} value={partner_id}>
                        {partner_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
              <Divider />
              {selected ? (
                <>
                  <DynamicForm settings={section?.settings} />
                  <Divider />
                  {section?.settings && section?.settings.length > 0 && (
                    <Box
                      sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'row',
                        px: 4,
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button variant="contained" type="submit" sx={{ px: 6 }}>
                        Save
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <Message text="Please Choose Instance In Dropdown Above" />
              )}
            </Box>
          </form>
          <LoadingBackdrop open={submitting} />
        </>
      )}
    </Form>
  );
};

const DynamicForm = ({ settings }: IDynamicFormProps) => {
  if (!settings || settings.length === 0) {
    return <Message text="This feature is not available" />;
  }
  settings.sort((a, b) => a.sort_order - b.sort_order);

  return (
    <Box
      sx={{
        flex: 1,
        background: '#f8f8fa',
        maxHeight: `calc(100% - 144px)`,
        overflow: 'auto'
      }}
    >
      <Stack
        direction="column"
        divider={<Divider sx={{ mx: 12 }} orientation="horizontal" flexItem />}
      >
        {settings.map(item => (
          <Box key={item.setting_key}>{renderControl(item)}</Box>
        ))}
      </Stack>
    </Box>
  );
};

const Message = ({ text }: { text: string }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      {text}
    </Box>
  );
};
