import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Checkbox,
  Box
} from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../../trash.svg';
const rows = [{ name: 'Guest Users' }, { name: 'Account Admins' }];

const Icon = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-havevq-MuiSvgIcon-root"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
  >
    <rect x="4" width="16" y="4" height="16" fill="white" />
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
  </svg>
);

export const UserTypeTable = () => {
  return (
    <Box sx={{ mx: 4 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User Type</TableCell>
            <TableCell>MAU</TableCell>
            <TableCell>Video</TableCell>
            <TableCell>Tier Pricing</TableCell>
            <TableCell>
              <Button variant="contained">Add</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>
                <Checkbox icon={<Icon />} />
              </TableCell>
              <TableCell>
                <Checkbox icon={<Icon />} />
              </TableCell>
              <TableCell>$1 - 10</TableCell>
              <TableCell>
                <IconButton>
                  <DeleteIcon height={14} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
