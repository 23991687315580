import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button } from '@mui/material';
import { format } from 'date-fns';
import { IPartner } from 'src/types';

interface IInstanceTableProps {
  data: IPartner[];
}

export function InstanceTable({ data }: IInstanceTableProps) {
  return (
    <Box sx={{ height: 265, overflow: 'auto' }}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell size="small" align="left" sx={{ fontSize: 10, width: 40 }}>
              ID
            </TableCell>
            <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
              Name
            </TableCell>
            <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
              Domain
            </TableCell>
            <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
              Created
            </TableCell>
            <TableCell size="small" />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              key={row.partner_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 10 }}
            >
              <TableCell
                component="th"
                scope="row"
                size="small"
                align="left"
                sx={{ fontSize: 10, width: 40 }}
              >
                {row.partner_id}
              </TableCell>
              <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
                {row.partner_name}
              </TableCell>
              <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
                {row.partner_domain_name}
              </TableCell>
              <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
                {format(new Date(row.partner_date_created), 'MM/dd/yyyy')}
              </TableCell>
              <TableCell size="small" align="left" sx={{ fontSize: 10 }}>
                <Button
                  sx={{ fontSize: 10 }}
                  variant="contained"
                  size="small"
                  onClick={() =>
                    window.open('https://' + row.partner_domain_name + '/auth/sso/dshd', '_blank')
                  }
                >
                  Log In
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
