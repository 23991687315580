import ReactDOM from 'react-dom';
import { useGetCardDetailsContext } from 'src/components';
import { ClientCard } from './ClientCard/ClientCard';

const cardsRoot = document.getElementById('cards')!;

export const ClientCards = () => {
  const { list } = useGetCardDetailsContext();

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        zIndex: 1250, // 1300 value for menus
        top: 'calc(30% - 150px)',
        left: 'calc(30% - 200px)'
      }}
    >
      {list.map(({ id, title }, order) => (
        <ClientCard id={id} key={id} order={order} title={title} />
      ))}
    </div>,
    cardsRoot
  );
};
