import { Field } from 'react-final-form';
import { ISetting } from '../CardDetails/ClientCard/types';
import { CustomTextField, StyledControlLabel } from './styled';

interface IDynamicNumberProps {
  settings: ISetting;
}

const composeValidators = (validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const DynamicNumber = ({
  settings: { required, setting_name, setting_key, number_max, number_min, number_precision }
}: IDynamicNumberProps) => {
  const validators = [];
  if (required) {
    validators.push(requiredFn);
  }
  if (number_max) {
    validators.push(maxValue(number_max));
  }
  if (number_min) {
    validators.push(minValue(number_min));
  }

  return (
    <Field name={setting_key} type="number" validate={composeValidators(validators)}>
      {({ input, meta }) => (
        <StyledControlLabel label={setting_name}>
          <CustomTextField
            {...input}
            size="small"
            type="number"
            fullWidth
            error={!!meta.error}
            sx={{ background: '#fff', flex: 1 }}
            hiddenLabel
            variant="outlined"
            helperText={meta.error ?? ''}
          />
        </StyledControlLabel>
      )}
    </Field>
  );
};
const requiredFn = (value: string) => (value ? undefined : 'Required');
const maxValue = (max: number) => (value: string) =>
  Number(value) > max ? `Value cannot be more than ${max}` : undefined;
const minValue = (min: number) => (value: string) =>
  Number(value) < min ? `Value cannot be less than ${min}` : undefined;
