import React, { createContext, useContext, useEffect, useState } from 'react';

type AlertType = 'error' | 'warning' | 'info' | 'success';

const defaultValues = {
  type: 'error' as AlertType,
  open: false,
  text: '',
  setState: (param: { type: AlertType; text: string; open: boolean }): void => undefined,
  close: (): any => undefined
};

const SBContext = createContext(defaultValues);

interface IProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvder = ({ children }: IProviderProps) => {
  const [state, setState] = useState<{ type: AlertType; open: boolean; text: string }>({
    type: 'error',
    open: false,
    text: ''
  });

  return (
    <SBContext.Provider
      value={{ setState, close: () => setState(x => ({ ...x, open: false })), ...state }}
    >
      {children}
    </SBContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SBContext);
