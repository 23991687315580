import { Box } from '@mui/material';
import { useGetClientInfo } from 'src/api';
import { LoadingBackdrop } from 'src/components';
import { transformOrganization } from 'src/helper';
import { EditClientData } from './EditClientData';
import { MainInfo } from './MainInfo';

export const Info = ({ clientId }: { clientId: number }) => {
  const { data, isLoading } = useGetClientInfo(clientId);
  const client = transformOrganization(data);

  if (isLoading || !client || !data) {
    return <LoadingBackdrop />;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
      <MainInfo client={client} />
      <EditClientData client={data} />
    </Box>
  );
};
