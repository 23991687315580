import { useCallback, useRef } from 'react';

export function useDebounce<T = any>(cb: (params: T) => void, timeout = 500) {
  const timerIdref = useRef<any>(0);

  return useCallback(
    (params: T) => {
      if (timerIdref.current) {
        clearTimeout(timerIdref.current);
      }
      timerIdref.current = setTimeout(() => cb(params), timeout);
    },
    [cb, timeout]
  );
}
