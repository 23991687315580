import { Box, IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { ISetting } from '../CardDetails/ClientCard/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomTextField, StyledControlLabel } from './styled';
import { useDebounce } from 'src/util';

interface IDynamicTextBoxProps {
  settings: ISetting;
}

export const DynamicMultiTextBox = ({ settings }: IDynamicTextBoxProps) => {
  return (
    <FieldArray name={settings.setting_key}>
      {({ fields }) => <ItemField settings={settings} fields={fields} />}
    </FieldArray>
  );
};

interface IItemFieldProps {
  settings: ISetting;
  fields: FieldArrayRenderProps<string, any>['fields'];
}

function ItemField({ settings: { setting_name }, fields }: IItemFieldProps) {
  const callback = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = target.value;
      if (value) {
        fields.push(value);
        target.value = '';
      }
    },
    [fields]
  );
  const updateFields = useDebounce(callback, 2000);
  return (
    <StyledControlLabel label={setting_name}>
      <Box sx={{ flex: 1 }}>
        {fields.map((name, index) => (
          <Field name={name} key={name}>
            {({ input }) => (
              <Box sx={{ my: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconButton
                  onClick={ev => {
                    ev.stopPropagation();
                    fields.remove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <CustomTextField
                  multiline
                  fullWidth
                  {...input}
                  size="small"
                  sx={{ background: '#fff' }}
                />
              </Box>
            )}
          </Field>
        ))}
        <Box sx={{ pl: 5 }}>
          <CustomTextField
            fullWidth
            size="small"
            multiline
            sx={{ textOverflow: 'ellipsis', background: '#fff' }}
            onChange={updateFields}
          />
        </Box>
      </Box>
    </StyledControlLabel>
  );
}
