import { Typography, Box, Button } from '@mui/material';
import { ChartWrapper } from './Chart';
import { InstanceTable } from './InstanceTable';
import { format } from 'date-fns';
import { IGrouppedClient } from 'src/types';

interface IMainInfoProps {
  client: IGrouppedClient;
}

export const MainInfo = ({
  client: {
    partners,
    organization_website,
    organization_id,
    organization_name,
    organization_status_name,
    organization_date_created
  }
}: IMainInfoProps) => {
  const isSingleInstance = partners.length === 1;

  return (
    <Box
      sx={{
        flex: 1,
        px: 4,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Typography sx={{ fontSize: 18, fontWeight: 700 }} variant="title">
        {organization_name}
      </Typography>
      {isSingleInstance && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
          <Button
            variant="contained"
            onClick={() =>
              window.open('https://' + partners[0].partner_domain_name + '/auth/sso/dshd', '_blank')
            }
          >
            Log In
          </Button>
          <Typography sx={{ ml: 2 }}>{partners[0].partner_domain_name}</Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
        <Box sx={{ flex: 1, mr: 2 }}>
          <KeyValue title="Client ID" value={organization_id} />
          {isSingleInstance ? (
            <KeyValue title="Instance ID" value={partners.map(x => x.partner_id).join('')} />
          ) : null}
          <KeyValue title="Status" value={organization_status_name} />
          <KeyValue
            title="Created"
            value={format(new Date(organization_date_created), 'MM/dd/yyyy')}
          />
          {isSingleInstance && (
            <KeyValue title="Integration" value={Math.random() > 0.5 ? 'Yes' : 'No'} />
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          {isSingleInstance && <KeyValue title="Monthly Active Users" value={100} />}
          {isSingleInstance && <KeyValue title="Total Projects" value={10} />}
          {isSingleInstance && <KeyValue title="Total Templates" value={20} />}
          <KeyValue title="Avg Monthly Revenue" value={1000} />
          <KeyValue title="Total Revenue" value={10000} />
        </Box>
      </Box>
      {!isSingleInstance && <InstanceTable data={partners} />}
      <ChartWrapper />
    </Box>
  );
};

const KeyValue = ({ title, value }: { title: string; value: string | number }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <Typography variant="main" sx={{ mr: 2, fontSize: 10 }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: 10 }}>{value}</Typography>
    </Box>
  );
};
