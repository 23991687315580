import { Box, Button } from '@mui/material';
import { Field } from 'react-final-form';
import { ISetting } from '../CardDetails/ClientCard/types';
import { StyledControlLabel } from './styled';
import UploadIcon from '@mui/icons-material/Upload';
import { useRef, useState } from 'react';

interface IDynamicFileProps {
  settings: ISetting;
}

export const DynamicFile = ({ settings: { setting_name, setting_key } }: IDynamicFileProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState('');

  return (
    <Field name={setting_key} type="file">
      {({ input }) => (
        <StyledControlLabel label={setting_name}>
          <Box
            sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Button startIcon={<UploadIcon />} onClick={() => inputRef.current?.click()}>
              Upload
            </Button>
            <input
              ref={inputRef}
              type="file"
              name={input.name}
              onChange={evt => {
                const files = inputRef.current?.files!;

                if (files.length) {
                  const file = files[0];
                  if (file.type.includes('image')) {
                    setImage(URL.createObjectURL(file));
                  } else {
                    inputRef.current!.value = '';
                  }
                }
              }}
              style={{ position: 'absolute', width: 0, height: 0 }}
            />
            <Box
              style={{
                backgroundColor: '#f8f8fa'
              }}
              sx={{
                width: 30,
                height: 30,
                backgroundImage: `url('${image ?? input.value}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
              }}
            />
          </Box>
        </StyledControlLabel>
      )}
    </Field>
  );
};
