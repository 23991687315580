import { Box, Drawer, styled } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import MuiTreeItem from '@mui/lab/TreeItem';
import React, { useEffect, useState } from 'react';
import { Admins, Billing, Contracts, Editor, Info, Invoices, Usage } from './Sections';
import { DynamicPage } from './Sections/DynamicSection';

const TreeItem = styled((props: any) => <MuiTreeItem {...props} />)(({ theme }) => ({
  '& .MuiTreeItem-label': {
    height: 30,
    fontSize: 14,
    display: 'flex',
    lineHeight: '3 !important',
    alignItems: 'center'
  }
}));

const ChildTreeItem = styled(TreeItem)`
  & .MuiTreeItem-label {
    font-size: 12px;
  }
`;

const drawerWidth = '150px';
export const toolbarHeight = '30px';

const renderSection = (id: string, clientId: number) => {
  switch (id) {
    case 'info':
      return <Info clientId={clientId} />;
    case 'billing':
      return <Billing />;
    case 'contracts':
      return <Contracts id={clientId} />;
    case 'invoices':
      return <Invoices />;
    case 'editor':
      return <Editor />;
    case 'admins':
      return <Admins />;
    case 'usage':
      return <Usage />;
  }

  return <DynamicPage id={clientId} title={id} />;
};

export const CardContent = ({ id }: { id: number }) => {
  const [selectedId, setSelectedId] = useState('info');

  useEffect(() => {
    if (selectedId === 'features') {
      setSelectedId('General');
    }

    if (selectedId === 'billing') {
      setSelectedId('contracts');
    }
  }, [selectedId]);

  return (
    <>
      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          height: 650,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            zIndex: -10
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <Box sx={{ height: toolbarHeight }} />
        <TreeView
          defaultCollapseIcon={<ArrowDown />}
          defaultExpandIcon={<ArrowRight />}
          selected={selectedId}
          onNodeSelect={(_: React.SyntheticEvent<Element, Event>, node: string) => {
            setSelectedId(node);
          }}
          sx={{ height: 240, flexGrow: 1, maxWidth: drawerWidth, overflowY: 'auto' }}
        >
          <TreeItem
            nodeId="info"
            label="Info"
            style={{ marginTop: '27px' }}
            sx={'info' === selectedId ? SELECTED_TOP_NODE : {}}
          />
          <TreeItem
            nodeId="billing"
            label="Billing"
            sx={
              ['contracts', 'invoices', 'line1', 'line2', 'line3'].includes(selectedId)
                ? SELECTED_TOP_NODE
                : {}
            }
          >
            <ChildTreeItem
              sx={childStyle(selectedId, 'contracts')}
              nodeId="contracts"
              label="Contracts"
            />
            <ChildTreeItem
              nodeId="invoices"
              label="Invoices"
              sx={childStyle(selectedId, 'invoices')}
            />
            <ChildTreeItem nodeId="line1" label="Line" sx={childStyle(selectedId, 'line1')} />
            <ChildTreeItem nodeId="line2" label="Line" sx={childStyle(selectedId, 'line2')} />
            <ChildTreeItem nodeId="line3" label="Line" sx={childStyle(selectedId, 'line3')} />
          </TreeItem>
          <TreeItem
            nodeId="features"
            label="Features"
            sx={['General', 'Print', 'Integrations'].includes(selectedId) ? SELECTED_TOP_NODE : {}}
          >
            <ChildTreeItem
              nodeId="General"
              label="General"
              sx={childStyle(selectedId, 'General')}
            />
            <ChildTreeItem nodeId="Print" label="Print" sx={childStyle(selectedId, 'Print')} />
            <ChildTreeItem
              nodeId="Integrations"
              label="Integrations"
              sx={childStyle(selectedId, 'Integrations')}
            />
          </TreeItem>
          {false && <TreeItem nodeId="admins" label="Admins" />}
          {false && <TreeItem nodeId="usage" label="Usage" />}
        </TreeView>
      </Drawer>
      <Box
        sx={{
          position: 'absolute',
          left: drawerWidth,
          top: toolbarHeight,
          width: `calc(100% - ${drawerWidth})`,
          height: `calc(100% - 30px)`
        }}
      >
        {renderSection(selectedId, id)}
      </Box>
    </>
  );
};

const SELECTED_TOP_NODE = {
  background: (theme: any) => theme.palette.primary.main,
  color: 'white',
  opacity: 1,
  '& > ul': {
    background: '#f8f8fa',
    marginLeft: 0,
    paddingLeft: '10px',
    marginBottom: '30px'
  }
};

const childStyle = (selectedId: string, nodeId: string) => {
  if (selectedId === nodeId) {
    return {
      fontSize: '12px !important',
      lineHeight: '2 !important',
      background: 'transparent',
      color: 'black',
      '& *': {
        fontWeight: '800 !important',
        background: 'transparent !important'
      }
    };
  }
  return {
    fontSize: '12px !important',
    lineHeight: '2 !important',
    background: 'transparent',
    color: 'black',
    '& *:hover, & *:active, & *:focus': {
      fontWeight: '500 !important',
      background: 'transparent !important'
    }
  };
};
