import { Button, Box } from '@mui/material';
import { Form } from 'react-final-form';
import { useGetBusinessType, useGetIndustries, useGetUsers, useUpdateClient } from 'src/api';
import { FieldText, LoadingBackdrop } from 'src/components';
import { FieldSelect } from 'src/components';
import { useSnackbarContext } from 'src/context/SnackBarContext';
import { IClient, IOrganizationDTO } from 'src/types';

interface IEditClientDataProps {
  client: IOrganizationDTO;
}

export const EditClientData = ({ client }: IEditClientDataProps) => {
  const { data: businessTypesResponse, isLoading: isLoading1 } = useGetBusinessType();
  const { data: stuffResponse, isLoading: isLoading2 } = useGetUsers();
  const { data: industryiesResponse, isLoading: isLoading3 } = useGetIndustries();
  const industries = industryiesResponse?.data.items ?? [];
  const businessTypes = businessTypesResponse?.data.items ?? [];
  const stuff = stuffResponse ?? [];
  const { setState } = useSnackbarContext();
  const { mutateAsync } = useUpdateClient({
    onSuccess: () => {
      setState({ type: 'success', text: 'Updated!', open: true });
    },
    onError: () => {
      setState({ type: 'error', text: "Error, couldn't update!", open: true });
    }
  });

  const init: Partial<IClient> = {
    organization_name: client.name,
    organization_website: client.organization_website,
    organization_industry_id: client.industry_id,
    organization_business_type_id: client.business_type_id,
    organization_salesperson_user_id: client.salesperson_user_id,
    organization_account_manager_user_id: client.account_manager_user_id
  };

  const onSubmit = async (params: IClient) => {
    const body: Partial<IClient> = {};
    for (const key in params) {
      const prop = key as keyof IClient;
      if (params[prop] !== init[prop]) {
        body[prop] = params[prop] as any;
      }
    }
    const { errors } = await mutateAsync({ id: client.id, body });
    return errors;
  };

  return (
    <Box sx={{ minWidth: 300, py: 2, px: 3, background: '#f8f8fa' }}>
      {isLoading1 || isLoading2 || isLoading3 ? (
        <LoadingBackdrop />
      ) : (
        <Form<IClient> onSubmit={onSubmit} initialValues={init}>
          {({ handleSubmit, submitting }) => (
            <>
              <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center'
                  }}
                >
                  <FieldText
                    name="organization_name"
                    label="Client Name"
                    isRequired
                    requiredErrorMessage="Client Name is required."
                    isMaxLength
                    maxLengthErrorMessage="Client Name must be 50 characters or less."
                  />
                  <FieldText
                    name="organization_website"
                    label="Client Website"
                    isMaxLength
                    maxLengthErrorMessage="Client Website must be 50 characters or less."
                  />
                  <FieldSelect
                    name="organization_industry_id"
                    label="Industry"
                    options={industries}
                  />
                  <FieldSelect
                    name="organization_business_type_id"
                    label="Business Type"
                    options={businessTypes}
                  />
                  <FieldSelect
                    name="organization_salesperson_user_id"
                    label="Salesperson"
                    options={stuff}
                  />
                  <FieldSelect
                    name="organization_account_manager_user_id"
                    label="Account Manager"
                    options={stuff}
                  />
                  <Box sx={{ flex: 1 }} />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ width: 197, height: 37 }}
                  >
                    Save
                  </Button>
                </Box>
              </form>
              <LoadingBackdrop open={submitting} />
            </>
          )}
        </Form>
      )}
    </Box>
  );
};
