import axios from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { ISection } from './components/CardDetails/ClientCard/types';
import { IClient, IContract, IGetUser, IOrganizationDTO, IUser } from './types';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  timeout: 30000,
  withCredentials: true
});

interface IListResponse<T> {
  success: true;
  data: {
    page: number;
    limit: number;
    total: number;
    items: T[];
  };
}

type Options = { onSuccess?: () => void; onError?: () => void };

export function useProfile() {
  return useQuery('profile', apiService.profile, { cacheTime: 15 });
}

export function useLogout(
  options?: Omit<UseMutationOptions<boolean, unknown, void, void>, 'mutationFn'> | undefined
) {
  return useMutation(apiService.logout, options);
}

export function useGetClientInfo(id: number) {
  return useQuery(['client', id], () => apiService.getClient(id));
}

export function useGetPartnerConfig(id: number, partnerId?: number) {
  return useQuery(['config', id, partnerId], () => apiService.getClientConfig(id, partnerId));
}

export function useGetContract(id: number, partnerId: number, contractId: number) {
  return useQuery(['contract', id, partnerId, contractId], () =>
    apiService.getPartnerContract(id, partnerId, contractId)
  );
}

export function useGetUsers() {
  return useQuery('users', async () => {
    const users = await apiService.getUserList(200, 0, '', '');
    return users.data.items.map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`
    }));
  });
}

export function useUserList(limit = 200, skip = 0, search = '', department = 'all') {
  return useQuery(['users', limit, skip, search, department], () =>
    apiService.getUserList(limit, skip, search, department)
  );
}

export function useGetBusinessType() {
  return useQuery('business', apiService.getBusinessTypes);
}

export function useGetDepartments() {
  return useQuery('departments', apiService.getDepartments);
}

export function useGetUser(id: string) {
  return useQuery(['user', id], () => apiService.getUser(id), { enabled: !!id });
}

export function usePatchUser(options?: Options) {
  return useMutation(
    ({ id, body }: { id: string; body: Partial<IGetUser> }) => apiService.patchUser(id, body),
    {
      onSuccess: data => {
        if (data.success) {
          options?.onSuccess?.();
        } else {
          options?.onError?.();
        }
      },
      onError() {
        options?.onError?.();
      }
    }
  );
}

export function useCreateUser(options?: Options) {
  return useMutation((body: Partial<IGetUser>) => apiService.createUser(body), {
    onSuccess: data => {
      if (data.success) {
        options?.onSuccess?.();
      } else {
        options?.onError?.();
      }
    },
    onError() {
      options?.onError?.();
    }
  });
}

export function useRemoveUser() {
  const queryClient = useQueryClient();
  return useMutation((id: number) => apiService.deleteUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    }
  });
}

export function useGetIndustries() {
  return useQuery('industries', apiService.getIndustries);
}

export function useUpdateClient(options?: Options) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, body }: { id: number; body: Partial<IClient> }) => apiService.updateClient(id, body),
    {
      onSuccess: data => {
        if (data.success) {
          queryClient.invalidateQueries(['client', data.id]);
          queryClient.invalidateQueries('clients');
          options?.onSuccess?.();
        } else {
          options?.onError?.();
        }
      },
      onError() {
        options?.onError?.();
      }
    }
  );
}

export function useUpdateConfig(options?: Options) {
  return useMutation(
    ({
      clientId,
      partnerId,
      config
    }: {
      clientId: number;
      partnerId: number;
      config: Record<string, any>;
    }) => apiService.updateParnterConfig(clientId, partnerId, config),
    {
      onSuccess: () => {
        options?.onSuccess?.();
      },
      onError() {
        options?.onError?.();
      }
    }
  );
}

export function useGetClientList(
  take: number,
  skip: number,
  search: string,
  industry: string,
  business: string,
  status: string,
  accountManager: string,
  salesperson: string
) {
  return useQuery(
    ['clients', take, skip, search, industry, business, status, accountManager, salesperson],
    () =>
      apiService.getClients(
        take,
        skip,
        search,
        industry,
        business,
        status,
        accountManager,
        salesperson
      )
  );
}

export function useGetContracts(clientId: number, partnerId: number, statusId: number) {
  return useQuery([clientId, partnerId, statusId, 'contracts'], () =>
    partnerId
      ? apiService.getPartnerContracts(clientId, partnerId, statusId)
      : apiService.getClientContracts(clientId, statusId)
  );
}

export function useGetContractStatuses() {
  return useQuery('contrat-statuses', () => apiService.getContractStatuses());
}

class ApiService {
  async profile() {
    try {
      const profile = await instance.get<{
        success: boolean;
        email: string;
        first_name: string;
        last_name: string;
        id: number;
      }>('auth/profile/');

      return profile.data;
    } catch (e) {
      console.log(e);
    }
  }

  async logout() {
    try {
      await instance.post('auth/logout/');

      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  async getClients(
    take: number,
    skip: number,
    search: string,
    industry: string,
    business: string,
    status: string,
    accountManager: string,
    salesperson: string
  ) {
    let query = `clients?take=${take}&skip=${skip}`;
    if (search) {
      query += `&search=${search}`;
    }
    if (industry !== 'all') {
      query += `&industry=${industry}`;
    }
    if (business !== 'all') {
      query += `&business=${business}`;
    }
    if (status !== 'all') {
      query += `&status=${status}`;
    }
    if (accountManager !== 'all') {
      query += `&account_manager=${accountManager}`;
    }
    if (salesperson !== 'all') {
      query += `&salesperson=${salesperson}`;
    }
    const response = await instance.get<IListResponse<IClient>>(query);

    return response.data;
  }

  async getClient(id: number) {
    const response = await instance.get<{ data: IOrganizationDTO; success: boolean }>(
      `clients/${id}`
    );

    return response.data.data;
  }

  async getUserList(take: number, skip: number, search: string, department: string) {
    let query = `users?take=${take}&skip=${skip}`;
    if (search) {
      query += `&search=${search}`;
    }
    if (department !== 'all') {
      query += `&department=${department}`;
    }
    const response = await instance.get<IListResponse<IUser>>(query);

    return response.data;
  }

  async getBusinessTypes() {
    const response = await instance.get<IListResponse<{ id: string; name: string }>>(
      `details/business-types`
    );

    return response.data;
  }

  async getIndustries() {
    const response = await instance.get<IListResponse<{ id: string; name: string }>>(
      `details/industries`
    );

    return response.data;
  }

  async deleteUser(id: number) {
    const response = await instance.delete(`users/${id}`);

    return response.data;
  }

  async getClientConfig(id: string | number, partnerId?: string | number) {
    if (!partnerId) {
      return undefined;
    }
    const response = await instance.get<ISection[]>(`clients/${id}/partners/${partnerId}/config`);

    return response.data;
  }

  async updateClient(id: number, body: Partial<IClient>) {
    const response = await instance.patch<{
      success: boolean;
      id: string;
      errors?: { [key: string]: string };
    }>(`clients/${id}`, body);

    return response.data;
  }

  async getUser(id: string) {
    const response = await instance.get<{ sucess: Boolean; data: IGetUser }>(`users/${id}`);

    return response.data;
  }

  async patchUser(id: string, body: Partial<IGetUser>) {
    const response = await instance.patch<{ success: boolean; errors: any }>(`users/${id}`, body);

    return response.data;
  }

  async createUser(body: Partial<IGetUser>) {
    const response = await instance.post<{ success: boolean; errors: any }>(`users/`, body);

    return response.data;
  }

  async updateParnterConfig(clientId: number, partnerId: number, config: Record<string, any>) {
    const response = await instance.put(`clients/${clientId}/partners/${partnerId}/config`, config);

    return response.data;
  }

  async getContractStatuses() {
    const response = await instance.get<IListResponse<{ id: string; name: string }>>(
      `details/contract-statuses`
    );

    return response.data?.data?.items;
  }

  async getDepartments() {
    const response = await instance.get<IListResponse<{ id: string; name: string }>>(
      `details/departments`
    );

    return response.data?.data?.items;
  }

  async getClientContracts(clientId: number, statusId: number) {
    const response = await instance.get<IListResponse<IContract>>(
      `clients/${clientId}/contracts?status=${statusId}`
    );

    return response.data?.data?.items;
  }

  async getPartnerContracts(clientId: number, partnerId: number, statusId: number) {
    const response = await instance.get<IListResponse<IContract>>(
      `clients/${clientId}/partners/${partnerId}/contracts?statusId=${statusId}`
    );

    return response.data?.data?.items;
  }

  async getPartnerContract(clientId: number, partnerId: number, contractId: number) {
    const response = await instance.get<{ data: IContract; success: boolean }>(
      `clients/${clientId}/partners/${partnerId}/contracts/${contractId}`
    );

    return response.data?.data;
  }
}

const apiService = new ApiService();
