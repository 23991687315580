import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: '#7c60ff'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    }
  },
  typography: {
    fontFamily: '"Open Sans",sans-serif',
    fontSize: 12,
    title: {
      fontWeight: 700,
      fontSize: 20,
      color: '#050505',
      display: 'block'
    },
    subtitle1: {
      fontSize: 12,
      color: '#A6A6A8'
    },
    main: {
      fontWeight: '700'
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#dfe1e4',
          background: '#dfe1e4'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 10,
          borderBottom: '1px solid #dfe1e4'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          fontSize: 12
        }
      }
    }
  }
});

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyVariants {
    title: TypographyStyleOptions;
    main: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyVariantsOptions {
    title?: TypographyStyleOptions;
    main?: TypographyStyleOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyPropsVariantOverrides {
    title: true;
    main: true;
  }
}
