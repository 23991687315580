import { Box, FormLabel, Switch, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field } from 'react-final-form';
import { ISetting } from '../CardDetails/ClientCard/types';
import { StyledControlLabel } from './styled';

interface IDynamicToggleProps {
  settings: ISetting;
}

export const DynamicToggle = ({ settings: { setting_name, setting_key } }: IDynamicToggleProps) => {
  return (
    <Field name={setting_key} type="checkbox">
      {({ input }) => (
        <StyledControlLabel label={setting_name}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>Off</Box>
            <Switch {...input} />
            <Box>On</Box>
          </Box>
        </StyledControlLabel>
      )}
    </Field>
  );
};
