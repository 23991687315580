import { Box } from '@mui/material';
import svg from './logo.svg';

export const Logo = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mr: '50px' }}>
      <img height="28" src={svg} alt="logo" />
    </Box>
  );
};
