import { Box, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useGetContracts } from 'src/api';
import { LoadingBackdrop } from 'src/components';
import { IPartner } from 'src/types';

interface IContractsTableProps {
  partnerId: number;
  clientId: number;
  statusId: number;
  onChange: (x: number) => void;
  contractId: number;
  partners: IPartner[];
}

export const ContractsTable = ({
  clientId,
  partnerId,
  contractId,
  statusId,
  partners,
  onChange
}: IContractsTableProps) => {
  const { data = [], isLoading } = useGetContracts(clientId, partnerId, statusId);
  const theme = useTheme();

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  return (
    <Box sx={{ maxHeight: 170, overflow: 'auto', mx: 4 }}>
      <Table aria-label="table" size="small">
        <TableHead>
          <TableRow>
            <TableCell size="small" align="center">
              Contract ID
            </TableCell>
            <TableCell size="small" align="left">
              Instance Name
            </TableCell>
            <TableCell size="small" align="left">
              Start Date
            </TableCell>
            <TableCell size="small" align="left">
              License Fee
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              key={row.contract_id}
              sx={{
                cursor: 'pointer',
                background: contractId === row.contract_id ? theme.palette.primary.main : undefined,
                color: contractId === row.contract_id ? 'white !important' : undefined
              }}
              onClick={() => onChange(row.contract_id)}
            >
              <TableCell
                sx={{
                  color: contractId === row.contract_id ? 'white' : undefined
                }}
                component="th"
                scope="row"
                size="small"
                align="center"
              >
                {row.contract_id}
              </TableCell>
              <TableCell
                sx={{
                  color: contractId === row.contract_id ? 'white' : undefined
                }}
                size="small"
              >
                {partners.find(x => x.partner_id === row.partner_id)?.partner_name}
              </TableCell>
              <TableCell
                sx={{
                  color: contractId === row.contract_id ? 'white' : undefined
                }}
                size="small"
                align="left"
              >
                {row.start_date ? format(new Date(row.start_date), 'MM/dd/yyyy') : ''}
              </TableCell>
              <TableCell
                sx={{
                  color: contractId === row.contract_id ? 'white' : undefined
                }}
                size="small"
                align="left"
              ></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
