import { Box, TextField } from '@mui/material';

interface ITierProps {
  label: string;
  value?: string | number;
}

export const Tier = ({ label, value }: ITierProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box sx={{ flex: 3, textAlign: 'right', fontSize: 10 }}>{label}</Box>
      <Box sx={{ mx: 1 }}>$</Box>
      <Box sx={{ flex: 2 }}>
        <TextField
          InputProps={{ sx: { fontSize: 10, background: 'white' } }}
          sx={{ maxWidth: 60 }}
          inputProps={{ sx: { padding: '2px 8px !important' } }}
          size="small"
          value={value}
        />
      </Box>
    </Box>
  );
};
