import { OutlinedInputProps, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field } from 'react-final-form';

const CustomTextField = styled((props: TextFieldProps & { height?: number }) => (
  <TextField InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme, height }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #dfe1e4',
    overflow: 'hidden',
    borderRadius: 4,
    color: '#000000 !important',
    height,
    fontWeight: '600 !important',
    fontSize: 14,
    backgroundColor: '#ffffff !important',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#ffffff !important'
    },
    '&.Mui-focused': {
      backgroundColor: '#ffffff !important',
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputLabel-root': {
    color: '#bbbbbc',
    fontWeight: '400',
    fontSize: 12
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: -20
  }
}));

interface IFieldText {
  name: string;
  label: string;
  type?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  height?: number;
  isRequired?: boolean;
  requiredErrorMessage?: string;
  isMaxLength?: boolean;
  maxLengthErrorMessage?: string;
  maxLength?: number;
  multiline?: boolean;
  sx?: any;
}

export const FieldText = ({
  name,
  label,
  type = 'text',
  variant = 'filled',
  height,
  multiline = false,
  isRequired = false,
  isMaxLength = false,
  requiredErrorMessage,
  maxLengthErrorMessage,
  sx = {},
  maxLength = 50
}: IFieldText) => {
  const validators = [];
  if (isRequired) {
    validators.push(required(requiredErrorMessage));
  }
  if (isMaxLength) {
    validators.push(maxLengthFn(maxLength, maxLengthErrorMessage));
  }

  return (
    <Field name={name} type={type} validate={composeValidators(validators)}>
      {({ input, meta }) => (
        <CustomTextField
          {...input}
          size="small"
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline={multiline}
          error={(!meta.valid || meta.submitError) && meta.touched && !meta.modifiedSinceLastSubmit}
          sx={{ my: 1, ...sx }}
          height={height}
          label={label.endsWith(':') ? label : label + ':'}
          variant={variant}
          helperText={
            meta.touched && !meta.modifiedSinceLastSubmit ? meta.error || meta.submitError : ''
          }
        />
      )}
    </Field>
  );
};
const required = (message?: string) => (value: string) => value ? undefined : message ?? 'Required';

const maxLengthFn = (size: number, message?: string) => (value: string) =>
  value?.length > size ? message : undefined;

const composeValidators = (validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
