import { Field } from 'react-final-form';
import { ISetting } from '../CardDetails/ClientCard/types';
import { CustomTextField, StyledControlLabel } from './styled';

interface IDynamicTextBoxProps {
  settings: ISetting;
}

const composeValidators = (validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const DynamicTextBox = ({
  settings: {
    required,
    setting_name,
    setting_key,
    string_max_length,
    string_regex_validation,
    string_regex_validation_error_message
  }
}: IDynamicTextBoxProps) => {
  const validators = [];
  if (required) {
    validators.push(requiredFn);
  }
  if (string_max_length) {
    validators.push(maxLengthFn(string_max_length));
  }
  if (string_regex_validation) {
    validators.push(regExpFn(string_regex_validation, string_regex_validation_error_message));
  }

  return (
    <Field name={setting_key} type="text" validate={composeValidators(validators)}>
      {({ input, meta }) => (
        <StyledControlLabel label={setting_name}>
          <CustomTextField
            {...input}
            size="small"
            fullWidth
            error={!!meta.error}
            sx={{ background: '#fff', flex: 1 }}
            hiddenLabel
            variant="outlined"
            helperText={meta.error ?? ''}
          />
        </StyledControlLabel>
      )}
    </Field>
  );
};
const requiredFn = (value: string) => (value ? undefined : 'Required');
const maxLengthFn = (size: number) => (value: string) =>
  value?.length > size ? `Max value length is ${size}` : undefined;
const regExpFn = (expression: string, message: string | undefined) => (value: string) => {
  const regExp = new RegExp(expression);
  return regExp.test(value) ? undefined : message ?? 'Failed';
};
