import { Chart } from '@antv/g2';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';

const data = [
  { month: 'Jan', value: 7 },
  { month: 'Feb', value: 4.2 },
  { month: 'Mar', value: 5.7 },
  { month: 'Apr', value: 8.5 },
  { month: 'May', value: 11.9 },
  { month: 'Jun', value: 15.2 },
  { month: 'Jul', value: 17 },
  { month: 'Aug', value: 16.6 },
  { month: 'Sep', value: 14.2 },
  { month: 'Oct', value: 10.3 },
  { month: 'Nov', value: 6.6 },
  { month: 'Dec', value: 4.8 }
];

const renderChart = (container: HTMLElement) => {
  const chart = new Chart({
    container,
    autoFit: true,
    height: 265
  });

  chart.data(data);
  chart.scale({
    month: {
      range: [0, 1]
    },
    value: {
      nice: true
    }
  });
  chart.axis('month', {
    label: {
      style: {
        fontSize: 10
      }
    }
  });
  chart.axis('value', {
    label: {
      style: {
        fontSize: 10
      }
    }
  });

  chart.tooltip({
    showCrosshairs: true,
    shared: true
  });

  chart.line().position('month*value').shape('smooth');

  chart.point().position('month*value').shape('circle').style({
    stroke: '#fff',
    lineWidth: 1
  });

  chart.render();
};

export function ChartWrapper() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    renderChart(containerRef.current);
  }, []);

  return <Box sx={{ mt: 2 }} ref={containerRef} />;
}
