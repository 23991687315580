import { Box, Button, Divider, Switch, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { useGetContract, useGetContracts } from 'src/api';
import { FieldSelect, FieldText, LoadingBackdrop } from 'src/components';
import { UserTypeTable } from './UserTypeTable';
import { Tier } from './Tier';
import { IPartner } from 'src/types';

interface IContractFromProps {
  contractId: number;
  clientId: number;
  partnerId: number;
  partners: IPartner[];
}

export const ContractForm = ({ contractId, clientId, partnerId, partners }: IContractFromProps) => {
  const { data: contracts = [] } = useGetContracts(clientId, partnerId, 1);
  const { data, isLoading } = useGetContract(clientId, partnerId, contractId);
  if (contractId === 0) {
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Select A Contract or Create A New One</Typography>
      </Box>
    );
  }
  if (isLoading) {
    return <LoadingBackdrop />;
  }

  return (
    <Box sx={{ minHeight: 170, height: '100%', background: '#f8f8fa' }}>
      <Form
        onSubmit={data => console.log(data)}
        initialValues={{
          instance: contracts.find(x => x.contract_id === contractId)?.partner_id,
          startDate: data?.start_date,
          per: 2,
          users: 1
        }}
      >
        {() => (
          <form style={{ height: 'calc(100% - 50px)' }}>
            <Box sx={{ display: 'flex', height: '100%' }}>
              <Box sx={{ flex: 5, overflowY: 'auto' }}>
                <Box sx={{ display: 'flex', pt: 3, mx: 4 }}>
                  <FieldSelect
                    name="instance"
                    label="Instance"
                    options={partners.map(x => ({ id: x.partner_id, name: x.partner_name }))}
                  />
                  <Box sx={{ width: 8 }} />
                  <FieldText name="startDate" label="Start Date"></FieldText>
                </Box>
                <Box sx={{ display: 'flex', mx: 4 }}>
                  <Box sx={{ flex: 1, display: 'flex' }}>
                    <FieldText name="lisence" label="License Fee" sx={{ flex: 1 }} />
                    <Box sx={{ width: 8 }} />
                    <FieldSelect
                      name="per"
                      label="Per"
                      sx={{ flex: 1 }}
                      options={[
                        { id: 1, name: 'Day' },
                        { id: 2, name: 'Month' }
                      ]}
                    />
                  </Box>
                  <Box sx={{ width: 8 }} />
                  <Box sx={{ flex: 1 }} />
                </Box>
                <Box sx={{ display: 'flex', mx: 4, mb: 2 }}>
                  <Box sx={{ flex: 1, display: 'flex' }}>
                    <FieldText name="setup" label="Setup Fee" />
                  </Box>
                  <Box sx={{ width: 24 }} />
                  <Box sx={{ flex: 3 }}></Box>
                </Box>
                <UserTypeTable />
                <Box sx={{ display: 'flex', mx: 4, mt: 6 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Pilot:
                    <Box sx={{ ml: 2 }}>No</Box>
                    <Switch />
                    <Box>Yes</Box>
                  </Box>
                  <Box sx={{ width: 8 }} />
                  <FieldText name="fee" label="Fee"></FieldText>
                  <Box sx={{ width: 8 }} />
                  <FieldText name="fee" label="Start Date"></FieldText>
                </Box>
              </Box>
              <Divider orientation="vertical" />
              <Box sx={{ flex: 3, flexDirection: 'column', display: 'flex', px: 2 }}>
                <Box sx={{ mx: 2, pt: 3 }}>
                  <FieldSelect name="users" label="Users" options={[{ id: 1, name: 'Users' }]} />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ flex: 6, textAlign: 'right', p: 1, fontSize: 10 }}>Tier</Box>
                  <Box sx={{ flex: 4, p: 1, fontSize: 10 }}>Cost</Box>
                  <Box sx={{ flex: 9, textAlign: 'right', p: 1, fontSize: 10 }}>Tier</Box>
                  <Box sx={{ flex: 6, p: 1, fontSize: 10 }}>Cost</Box>
                </Box>
                <Divider sx={{ mx: -2 }} />
                <Box sx={{ display: 'flex', my: 2, flex: 1 }}>
                  <Box
                    sx={{
                      flex: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Tier label="6-10" value="10.0" />
                    <Tier label="11-20" value="10.0" />
                    <Tier label="21-50" value="10.0" />
                    <Tier label="51-100" value="10.0" />
                    <Tier label="101-200" value="10.0" />
                    <Tier label="201-500" value="10.0" />
                    <Tier label="501-1000" value="10.0" />
                  </Box>
                  <Box
                    sx={{
                      flex: 6,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Tier label="1001-2000" value="10.0" />
                    <Tier label="2001-5000" value="10.0" />
                    <Tier label="5001-10000" value="10.0" />
                    <Tier label="10001-20000" value="10.0" />
                    <Tier label="20001-50000" value="10.0" />
                    <Tier label="50001-100000" value="10.0" />
                    <Tier label="100000 +" value="10.0" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Form>
      <Divider />
      <Box
        sx={{
          height: 48,
          background: 'white',
          display: 'flex',
          justifyContent: 'flex-end',
          px: 4,
          py: 1
        }}
      >
        <Button variant="contained">Add</Button>
      </Box>
    </Box>
  );
};
