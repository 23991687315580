import { FormControl, FormHelperText, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { Field } from 'react-final-form';

export const Dropdown = styled(Select)({
  background: '#ffffff !important',
  border: '1px solid #dfe1e4',
  borderRadius: 4,
  '& input': {
    border: 'none'
  },
  '& .MuiSelect-select': {
    background: 'transparent !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    color: '#000000'
  }
});

export const DropdownOutlined = styled(Dropdown)({
  '&.MuiOutlinedInput-root:hover, &.MuiOutlinedInput-root': {
    border: 'none',
    '& fieldset': {
      borderColor: '#dfe1e4'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#dfe1e4'
    },
    '&.Mui-activated fieldset': {
      borderColor: '#dfe1e4'
    }
  }
});

interface IFieldSelectProps {
  name: string;
  label: string;
  type?: string;
  height?: number;
  variant?: 'filled' | 'outlined' | 'standard';
  isRequired?: boolean;
  sx?: Record<string, string | number>;
  options: Array<{ name: string; id: string | number }>;
}

export const FieldSelect = ({
  name,
  label,
  type = 'text',
  variant = 'filled',
  height,
  isRequired = false,
  sx = {},
  options
}: IFieldSelectProps) => {
  return (
    <Field name={name} type={type} validate={isRequired ? required : undefined}>
      {({ input, meta }) => (
        <FormControl variant={variant} sx={{ minWidth: 80, my: 1, ...sx }} fullWidth>
          <InputLabel
            shrink
            error={
              (!meta.valid || meta.submitError) && meta.touched && !meta.modifiedSinceLastSubmit
            }
            sx={{ color: '#bbbbbc', fontWeight: '400', fontSize: 12 }}
          >
            {label.endsWith(':') ? label : label + ':'}
          </InputLabel>
          <Dropdown
            disableUnderline
            {...input}
            sx={height ? { height } : undefined}
            label={label}
            size="small"
            error={
              (!meta.valid || meta.submitError) && meta.touched && !meta.modifiedSinceLastSubmit
            }
          >
            {options.map(({ name, id: value }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Dropdown>
          {meta.touched && !meta.modifiedSinceLastSubmit && meta.invalid ? (
            <FormHelperText
              sx={{ position: 'absolute', bottom: -25 }}
              error={!!meta.error || !!meta.submitError}
            >
              {meta.error || meta.submitError}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    </Field>
  );
};
const required = (value: string) => (value ? undefined : 'Required');
