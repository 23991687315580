import { Box, Button, Divider, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { useGetClientInfo, useGetContractStatuses } from 'src/api';
import { DropdownOutlined, LoadingBackdrop } from 'src/components';
import { transformOrganization } from 'src/helper';
import { IPartner } from 'src/types';
import { ContractForm } from './ContractForm';
import { ContractsTable } from './ContractsTable';

interface IContractsProps {
  id: number;
}

const defaultEmptyList: IPartner[] = [];

export const Contracts = ({ id }: IContractsProps) => {
  const { data: row } = useGetClientInfo(id);
  const data = useMemo(() => transformOrganization(row), [row]);
  const { data: statuses = [], isLoading } = useGetContractStatuses();
  const partners = data?.partners ?? defaultEmptyList;
  const [partner, setPartner] = useState(0);
  const [status, setStatus] = useState(1);
  const [contract, setContract] = useState(0);

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        flexDirection: 'column',
        display: 'flex'
      }}
    >
      <Box
        sx={{
          height: 70,
          display: 'flex',
          flexDirection: 'row',
          px: 4,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <DropdownOutlined
          displayEmpty
          size="small"
          sx={{ width: 200, height: 37 }}
          onChange={(e: any) => setStatus(+e.target.value)}
          value={status}
        >
          {statuses.map(({ name, id }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </DropdownOutlined>
        <Box>
          <Button variant="contained" sx={{ px: 6, height: 37 }}>
            Create
          </Button>
          <DropdownOutlined
            displayEmpty
            size="small"
            sx={{ width: 200, m: 2, height: 37 }}
            onChange={(e: any) => setPartner(+e.target.value)}
            value={partner}
          >
            <MenuItem value={0}>All Instances</MenuItem>
            {partners.map(({ partner_name, partner_id }) => (
              <MenuItem key={partner_id} value={partner_id}>
                {partner_name}
              </MenuItem>
            ))}
          </DropdownOutlined>
        </Box>
      </Box>
      <Divider />
      <ContractsTable
        contractId={contract}
        clientId={id}
        partnerId={partner}
        partners={partners}
        statusId={status}
        onChange={setContract}
      />
      <Divider />
      <ContractForm contractId={contract} clientId={id} partnerId={partner} partners={partners} />
    </Box>
  );
};
