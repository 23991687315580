import { IClient, IGrouppedClient, IOrganizationDTO } from './types';

export const transformClient = (data: IClient[] | null | undefined) => {
  if (!data) {
    return null;
  }

  return data.reduce((result, x) => {
    const org = result.find(item => item.organization_id === x.organization_id);
    if (org) {
      org.partners.push({
        partner_name: x.partner_name,
        partner_id: x.partner_id,
        partner_date_created: x.partner_date_created,
        partner_domain_name: x.partner_domain_name,
        partner_status_name: x.partner_status_name,
        partner_status_id: x.partner_status_id
      });
    } else {
      result.push({
        organization_id: x.organization_id,
        organization_name: x.organization_name,
        organization_status_name: x.organization_status_name,
        organization_website: x.organization_website,
        organization_business_type_name: x.organization_business_type_name,
        organization_industry_name: x.organization_industry_name,
        organization_salesperson: `${x.organization_salesperson_first_name} ${x.organization_salesperson_last_name}`,
        organization_account_manager: `${x.organization_account_manager_first_name} ${x.organization_account_manager_last_name}`,
        organization_date_created: x.organization_date_created,
        partners: [
          {
            partner_name: x.partner_name,
            partner_id: x.partner_id,
            partner_date_created: x.partner_date_created,
            partner_domain_name: x.partner_domain_name,
            partner_status_name: x.partner_status_name,
            partner_status_id: x.partner_status_id
          }
        ]
      });
    }
    return result;
  }, [] as IGrouppedClient[]);
};

export const transformOrganization = (data: IOrganizationDTO | null | undefined) => {
  if (!data) {
    return null;
  }

  const result: IGrouppedClient = {
    organization_id: data.id,
    organization_name: data.name,
    organization_status_name: data.status.name,
    organization_website: data.organization_website,
    organization_date_created: data.date_created,
    partners: data.partners.map(x => ({
      ...x,
      partner_domain_name: x.domain_name,
      partner_status_id: x.partner_id,
      partner_status_name: x.status.name,
      partner_date_created: x.date_created
    })),
    organization_account_manager: '',
    organization_industry_name: '',
    organization_salesperson: '',
    organization_business_type_name: ''
  };

  return result;
};
