import { Alert, AppBar, Box, Button, Snackbar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { useLogout, useProfile } from './api';
import { LazyWrapper, LoadingBackdrop, Logo } from './components';
import { useSnackbarContext } from './context/SnackBarContext';

const Admin = React.lazy(() => import('./modules/Admin/Admin'));
const Billing = React.lazy(() => import('./modules/Billing/Billing'));
const Clients = React.lazy(() => import('./modules/Clients/Clients'));

function App() {
  const { data, isLoading } = useProfile();
  const { mutateAsync } = useLogout({
    onSuccess: () => {
      window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
    }
  });
  const { type, text, open, close } = useSnackbarContext();

  if (data === undefined && !isLoading) {
    console.log('redirect', window.location.href);
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?return=${encodeURIComponent(
      window.location.href
    )}`;
  }
  if (isLoading) {
    <LoadingBackdrop />;
  }

  return (
    <Box>
      <Snackbar
        open={open}
        onClose={close}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity={type} sx={{ width: '100%' }}>
          {text}
        </Alert>
      </Snackbar>
      <AppBar
        sx={{
          background: '#ffffff',
          zIndex: theme => theme.zIndex.drawer + 1,
          height: 68,
          boxShadow: 'none'
        }}
        position="fixed"
      >
        <Toolbar sx={{ height: '100%' }}>
          <Box sx={{ overflow: 'hidden' }}>
            <Logo />
          </Box>
          <Box sx={{ display: ['none', 'block'] }}>
            <NavLink activeClassName="activeCustomLink" className="customLink" to="/clients">
              Clients
            </NavLink>
            {false && (
              <NavLink activeClassName="activeCustomLink" className="customLink" to="/billing">
                Billing
              </NavLink>
            )}
            {
              <NavLink activeClassName="activeCustomLink" className="customLink" to="/admin">
                Admin
              </NavLink>
            }
          </Box>
          <Box sx={{ flex: 1 }} />
          <Button
            onClick={() => mutateAsync()}
            sx={{
              textTransform: 'none',
              fontSize: 14,
              fontWeight: 400
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path="/clients">
          <LazyWrapper>
            <Clients />
          </LazyWrapper>
        </Route>
        <Route path="/billing">
          <LazyWrapper>
            <Billing />
          </LazyWrapper>
        </Route>
        <Route path="/admin">
          <LazyWrapper>
            <Admin />
          </LazyWrapper>
        </Route>
        <Redirect to="/clients" />
      </Switch>
    </Box>
  );
}

export default App;
