import React, { createContext, useContext, useEffect, useState } from 'react';

const defaultValues = {
  list: [] as Array<{ id: number; title: string }>,
  add: (id: number, title: string): void => undefined,
  removeId: (id: number): void => undefined,
  clear: (): void => undefined
};

const CardContext = createContext(defaultValues);

interface ICardDetailsProviderProps {
  children: React.ReactNode;
}

export const CardDetailsProvder = ({ children }: ICardDetailsProviderProps) => {
  const [list, setList] = useState<Array<{ id: number; title: string }>>([]);

  useEffect(() => {
    return () => {
      setList([]);
    };
  }, []);

  const value = {
    removeId: (id: number) => setList(list => list.filter(x => x.id !== id)),
    add: (id: number, title: string) => {
      setList(list => {
        list = list.filter(x => x.id !== id);
        return [...list, { id, title }];
      });
    },
    clear: () => setList([]),
    list
  };

  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
};

export const useGetCardDetailsContext = () => useContext(CardContext);
