import { Box, DialogTitle, IconButton, Paper, PaperProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRef, MouseEvent } from 'react';
import Draggable from 'react-draggable';
import { useGetCardDetailsContext } from '../CardContext';
import { CardContent, toolbarHeight } from './CardContent';
import { useGetClientInfo } from 'src/api';

function PaperComponent({ sx, order, ...rest }: PaperProps & { order: number }) {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef} handle=".draggable-dialog-title">
      <Paper {...rest} sx={{ ...sx, position: 'fixed', zIndex: order }} ref={nodeRef} />
    </Draggable>
  );
}

export const ClientCard = ({ id, order, title }: { id: number; order: number; title: string }) => {
  const { removeId, add } = useGetCardDetailsContext();
  const { data } = useGetClientInfo(id);

  return (
    <PaperComponent order={order}>
      <DialogTitle
        sx={{
          minWidth: 1000,
          background: '#070707',
          color: '#f0f0f0',
          fontSize: 14,
          p: 0,
          height: toolbarHeight,
          '&:active': {
            cursor: 'move'
          }
        }}
        className="draggable-dialog-title"
        onMouseDown={() => add(id, title)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box
            sx={{ flex: 1, justifyContent: 'center', display: 'flex', fontWeight: 600 }}
          >{`${id} - ${data?.name ?? title}`}</Box>
          <IconButton
            size="small"
            sx={{ color: '#f0f0f0' }}
            aria-label="close"
            onClick={(ev: MouseEvent) => {
              ev.stopPropagation();
              removeId(id);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <CardContent id={id} />
    </PaperComponent>
  );
};
