import { Box, FormHelperText, MenuItem, Select } from '@mui/material';
import { Field } from 'react-final-form';
import { ISetting } from '../CardDetails/ClientCard/types';
import { StyledControlLabel } from './styled';

interface IDynamicDropDownProps {
  settings: ISetting;
}

export const DynamicDropDown = ({
  settings: { setting_key, setting_name, possible_values, required }
}: IDynamicDropDownProps) => {
  return (
    <Field name={setting_key} type="select" validate={!required ? requiredFn : undefined}>
      {({ input, meta }) => (
        <StyledControlLabel label={setting_name}>
          <Box sx={{ flex: 1, position: 'relative' }}>
            <Select displayEmpty {...input} sx={{ width: '100%' }} error={!!meta.error}>
              {possible_values.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {meta.error ? (
              <FormHelperText
                sx={{ position: 'absolute', bottom: -20, left: 0 }}
                error={!!meta.error}
              >
                {meta.error}
              </FormHelperText>
            ) : null}
          </Box>
        </StyledControlLabel>
      )}
    </Field>
  );
};
const requiredFn = (value: string) => (value ? undefined : 'Required');
