import { Box, styled, TextField } from '@mui/material';
import { ReactElement } from 'react';

export const StyledControlLabel = ({
  children,
  label
}: {
  children: ReactElement<any, any>;
  label: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      m: 2,
      px: 14
    }}
  >
    <Box sx={{ width: 300, mt: 1, fontWeight: 500 }}>{label}</Box>
    {children}
  </Box>
);

export const CustomTextField = styled(TextField)({
  '.MuiFormHelperText-sizeSmall': {
    bottom: -20,
    background: '#f8f8fa',
    margin: 0,
    padding: 2
  }
});
